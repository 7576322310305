import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingUpdate from '../../Components/Skeleton/LoadingUpdate';
import { GetData, PostData, apiheader } from '../../utils/fetchData';

import { VendersContext } from './../../context/Store';
const UpdateLocations = () => {
  let { isLang } = useContext(VendersContext);
  let { id } = useParams()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/locations/${id}/update`;
  const urlFetchData = `${process.env.REACT_APP_API_URL}/locations/${id}`;

  const [loading, setLoading] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [Data, setData] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()
  const items = [
    { label: <Link to={'/locations'}>  {isLang === "en" ? "locations  " : '    مواقع    '}</Link> },
    { label: <Link to={`/locations/edit/${id}`} className='bg-orange-100 p-2 border-round'> {isLang === "en" ? "Update location  " : '  تحديث موقع'}</Link> }
  ];
  const fetchSliderData = async () => {
    let data = await GetData(urlFetchData, apiheader)
    if (data?.status === 200) {
      setData(data?.data);
      console.log('test');
      setTimeout(() => {
        setIsloading(true)
      }, 2000);
    }
  }
  useEffect(() => {
    fetchSliderData()
  }, []);
  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      lat: Data?.lat || '',
      long: Data?.long || '',
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let { data } = await PostData(urlUpdateData, values, apiheader);
        console.log(data);
        if (data?.status === 200) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.message, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/locations')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.message, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.message, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.errors?.long);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        if (response.data.errors?.lat) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.lat, life: 3000 });
        if (response.data.errors?.long) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.long, life: 3000 });
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    formik.setValues({
      lat: Data?.lat || '',
      long: Data?.long || '',
    });
  }, [Data, formik.setValues]);

  return (
    <div>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      {
        isloading ?
          <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

            <div className="grid  mt-5  ">
              <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                <label htmlFor="lat" className="font-bold block mb-2">   {isLang === "en" ? "lat" : 'خطوط العرض'}      </label>
                <InputText
                  name='lat'
                  id="lat"
                  keyfilter="text"
                  className="w-full  p-inputtext-sm"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lat}
                />
              </div>
              <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
                <label htmlFor="long" className="font-bold block mb-2">   {isLang === "en" ? "long" : 'خطوط الطول'} </label>
                <InputText value={formik.values.long} name='long' id="long" keyfilter="text" className="w-full  p-inputtext-sm" dir='rtl' onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
              </div>
            </div>


            <div className="group btn_gapAr flex justify-content-center gap-4 ">
              <Button icon="pi pi-check" loading={loading} label={isLang == "en" ? 'Submit' : 'إرسال  '} type="submit" size='small' className='mt-3' />
              <Link to={'/locations'}>
                <Button label={isLang == "en" ? 'Cancel' : 'إلغاء  '} type="reset" outlined size='small' className='mt-3' />
              </Link>
            </div>
          </form> :
          <LoadingUpdate />
      }
    </div>
  );
}
export default UpdateLocations