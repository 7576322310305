import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetData, PostData, apiheader } from '../../utils/fetchData';
import { VendersContext } from '../../context/Store';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { fetchSliderData } from './../../helper/useProjects';
import img from '../../assets/Img';
import { InputTextarea } from 'primereact/inputtextarea';

const UpdateProducts = () => {
  let { isLang } = useContext(VendersContext);
  let { id } = useParams()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/products/${id}/update`;
  const urlFetchData = `${process.env.REACT_APP_API_URL}/products/${id}`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const [selectMultiImage, setSelectMultiImage] = useState([]);
  const [editedImages, setEditedImages] = useState([]);
  const [openImanes, setOpenImanes] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [parentId, setParentId] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState([]);
  const toast = useRef(null);
  let navigate = useNavigate()
  const items = [
    { label: <Link to={'/products'}>{isLang === "en" ? "Products  " : 'المنتجات'}  </Link> },
    { label: <Link to={`/products/edit/${id}`} className='bg-orange-100 p-2 border-round'>   {isLang === "en" ? "Update products  " : '  تعديل منتج  '}</Link> }
  ];
  const fetchproductsData = async () => {
    let { data } = await GetData(urlFetchData, apiheader)
    setData(data);
    setEditedImages(data?.images);
  }
  useEffect(() => {
    fetchproductsData()
  }, [id]);

  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      name_en: Data?.name_en || '',
      name_ar: Data?.name_ar || '',
      description_en: Data?.description_en || '',
      description_ar: Data?.description_ar || '',
      category_id: Data?.category_id || '',
      sub_category_id: Data?.sub_category_id || '',
      width: Data?.width || '',
      height: Data?.height || '',
      images: Data?.images || selectMultiImage,
      colors: ['', ''],
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let { data } = await PostData(urlUpdateData, values, apiheader);
        console.log(data);
        if (data?.status === 200) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.message, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/products')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.message, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.message, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);

      }
    }
  });
  //!hide images modal 
  const hideImagesProjectsDialog = (e) => setOpenImanes(false)

  //*****Handle multi-image select 
  const handleMultiImageSelect = (event) => setSelectMultiImage([...selectMultiImage, event.target.files[0]]);

  useEffect(() => {
    formik.setFieldValue('image', selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    formik.setValues({
      name_en: Data?.name_en || '',
      name_ar: Data?.name_ar || '',
      description_en: Data?.description_en || '',
      description_ar: Data?.description_ar || '',
      category_id: Data?.category_id || '',
      sub_category_id: Data?.sub_category_id || '',
      width: Data?.width || '',
      height: Data?.height || '',
      'colors[0]': Data.colors && Data.colors[0] ? Data.colors[0].color : '',
      'colors[1]': Data.colors && Data.colors[1] ? Data.colors[1].color : '',
      // images: Data?.images || selectMultiImage,
    });
  }, [Data, formik.setValues]);


  const handleColorChange = (index, color) => {
    formik.setValues({
      ...formik.values,
      [`colors[${index}]`]: color,
    });
  };

  const handleDeleteImageFunc = async (id) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/products/${id}/delete-image`, apiheader).then(({ data }) => {
      console.log(data);
      if (data?.status) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.message, life: 3000 });
        fetchproductsData()
        setTimeout(() => {
        }, 1000);
      }
    }).catch(({ response }) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });

    })

  };

  const fetchParentIdData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/categories/get/parent-categories`;
    let data = await GetData(url, apiheader);
    setParentId(data?.data);

  }
  const subCategorytIdData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/categories?per_page=-1`;
    let data = await GetData(url, apiheader);
    setSubCategoryId(data?.data);
    console.log(data?.data);
  }
  useEffect(() => {
    fetchParentIdData();
    subCategorytIdData()
  }, [])
  return (
    <div>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
      <div className="flex upload_image flex-column gap-3 bg-info justify-content-center align-items-center mt-5 mb-6">
          <img src={img.UploadImage} alt="" onClick={() => setOpenImanes(!openImanes)} />
          <label htmlFor="link" className="font-bold  text-center bg-info ">      {isLang === "en" ? "Add product photos" : 'تعديل صور المنتج'} </label>

        </div>
        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
          <label htmlFor="name_en" className="font-bold block mb-2"> {isLang === "en" ? "  Name (En)" : ' اسم المنتج  (En)'}   </label>
            <InputText
              name='name_en'
              id="name_en"
              keyfilter="text"
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name_en}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
          <label htmlFor="name_ar" className="font-bold block mb-2">  {isLang === "en" ? "  Name (Ar)" : ' اسم المنتج  (Ar)'} </label>
            <InputText value={formik.values.name_ar} name='name_ar' id="name_ar" keyfilter="text" className="w-full  p-inputtext-sm" dir='rtl' onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-2   ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
          <label htmlFor="description_en" className="font-bold block mb-2">{isLang === "en" ? "  Description (En)" : ' وصف المنتج  (En)'}</label>
             <InputTextarea rows={5} value={formik.values.description_en} name='description_en' id="description_en" keyfilter="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
          <label htmlFor="description_ar" className="font-bold block mb-2">{isLang === "en" ? "  Description (Ar)" : ' وصف المنتج  (Ar)'}</label>
             <InputTextarea rows={5} value={formik.values.description_ar} name='description_ar' id="description_ar" keyfilter="text" className="w-full  p-inputtext-sm" dir='rtl'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-2   ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
          <label htmlFor="width" className="font-bold block mb-2">    {isLang === "en" ? "Width" : 'عرض منتج'}   </label>
            <InputText value={formik.values.width} name='width' id="width" keyfilter="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
          <label htmlFor="height" className="font-bold block mb-2">  {isLang === "en" ? "Height" : ' طول المنتج  '}  </label>
            <InputText value={formik.values.height} name='height' id="height" keyfilter="text" className="w-full  p-inputtext-sm" dir='rtl'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-2   ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
          <label htmlFor="category_id" className="font-bold block mb-2"> {isLang === "en" ? "Category" : 'تصنيف'}    </label>
            <Dropdown
              options={parentId?.map(item => ({
                name: isLang === "en" ? item?.name_en : item?.name_ar,
                value: item.id,
              }))}
              filter
              id="category_id"
              name="category_id"
              optionLabel="name"
              optionValue="value"
              value={formik.values.category_id} // Add this line 
              onChange={(e) => formik.setFieldValue("category_id", e.value)}
              onBlur={formik.handleBlur}
              placeholder={isLang === "en" ? 'Select a category_id' : 'اختر '}
              className="w-full p-inputtext-sm"
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
          <label htmlFor="sub_category_id" className="font-bold block mb-2">{isLang === "en" ? "Sub_category " : 'فئة فرعية'}</label>
            <Dropdown
              options={subCategoryId?.map(item => ({
                name: isLang === "en" ? item?.name_en : item?.name_ar,
                value: item.id,
              }))}
              filter
              id="sub_category_id"
              name="sub_category_id"
              optionLabel="name"
              optionValue="value"
              value={formik.values.sub_category_id} // Add this line 
              onChange={(e) => formik.setFieldValue("sub_category_id", e.value)}
              onBlur={formik.handleBlur}
              placeholder={isLang === "en" ? 'Select a sub_category_id' : 'اختر '}
              className="w-full p-inputtext-sm"
            />
          </div>

        </div>
        <div className="grid  mt-2   ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col">
            <label htmlFor="color 1" className="font-bold block mb-2">
              {isLang === "en" ? "First color" : '   اللون الاول'}
            </label>
            <input
              type="color"
              value={formik.values['colors[0]']}
              onChange={(e) => handleColorChange(0, e.target.value)}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col">
            <label htmlFor="second color" className="font-bold block mb-2">
              {isLang === "en" ? "second color" : '   اللون الثاني'}
            </label>
            <input
              type="color"
              value={formik.values['colors[1]']}
              onChange={(e) => handleColorChange(1, e.target.value)}
            />

          </div>
        </div>
        <Dialog visible={openImanes}
          style={{ width: '50%' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? "Multi Images" : "صور متعددة"}
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          modal
          onHide={hideImagesProjectsDialog}
        >
          <div className="grid mt-2 flex flex-column">
            <div className="mt-3 mb-3 flex justify-content-center">
              <div className="mt-3 mb-3 flex justify-content-center">
                <label htmlFor='fileInput ' className='btn_gapAr' >
                  <Button label={isLang === "en" ? "Select Images " : ' أختر  صور من جهازك'} type='button' icon={'pi pi-image'} onClick={() => document.getElementById('fileInput').click()} />
                </label>
                <input
                  type='file'
                  id='fileInput'
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    formik.setFieldValue('images', [...selectMultiImage, event.target.files[0]]);
                    handleMultiImageSelect(event);
                  }}
                />
              </div>
            </div>


            {/*  Display selected images */}
            <div className="grid  mt-2   ">
              {selectMultiImage.map((image, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                  <img src={URL.createObjectURL(image)} alt={`Image ${index}`} className='w-full h-8rem border-round-xl ' />
                </div>
              ))}
            </div>
            <div className="grid  mt-2   ">
              {editedImages.map((item, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col">
                  <div className="relative  ">
                    <img
                      src={item.image}
                      alt={`Image ${index}`}
                      style={{ objectFit: 'contain' }}
                      className="w-full h-8rem border-round-xl "
                    />
                    <Button icon="pi pi-times" rounded severity="danger" aria-label="Cancel" className="btn__porfile bg_delete  absolute right-0 " onClick={() => handleDeleteImageFunc(item.id)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Dialog>
        <div className="btn_gapAr group flex justify-content-center gap-4 ">
          <Button icon="pi pi-check" loading={loading} label={isLang == "en" ? 'Submit' : 'إرسال  '} type="submit" size='small' className='mt-3' />
          <Link to={'/products'}>
            <Button label={isLang == "en" ? 'Cancel' : 'إلغاء  '} type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form>
    </div>
  );
}


export default UpdateProducts
