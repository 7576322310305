import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetData, apiheader } from '../../utils/fetchData';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { VendersContext } from '../../context/Store';
import { Paginator } from 'primereact/paginator';
import { Galleria } from 'primereact/galleria';

const ProductsData = () => {
    let { isLang } = useContext(VendersContext);
    const dt = useRef(null);
    const responsiveOptions = [
        {
            breakpoint: '991px',
            numVisible: 4
        },
        {
            breakpoint: '767px',
            numVisible: 3
        },
        {
            breakpoint: '575px',
            numVisible: 1
        }
    ]; 

    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [deleteproductsDialog, setDeleteproductsDialog] = useState(false);
    const [productsDialog, setProductsDialog] = useState(false); 
    const [IdDeleteproducts, setIdDeleteproducts] = useState(null);
    const [Data, setData] = useState(null);
    const [deleteLoadind, setdeleteLoadind] = useState(false);
    const [totalPage, settotalPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(0); // State to keep track of the current page
    const [rows, setRows] = useState(1);
    const [images, setImages] = useState([]);


    // Add new products
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2">
                <Link to="/products/add">
                    <Button label={isLang === "en" ? "New products  " : 'إضافة منتج'} size='small' icon="pi pi-plus" />
                </Link>
            </div>
        );
    };



    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2">
                <Link to={`/products/edit/${rowData.id}`}>
                    <Button icon="pi pi-pencil" rounded outlined className="mr-2" />
                </Link>
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteproducts(rowData)} />
            </div>
        );
    };



    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">{isLang === "en" ? "Search by category name and description " : '    إبحث حسب اسم المنتج او وصف    '} </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={isLang === "en" ? "Search..." : ' بحـــث... '} />
            </span>

        </div>
    );

    const fetchProfileData = async (page) => {
        const url = `${process.env.REACT_APP_API_URL}/products/?page=${page + 1}`;
        let data = await GetData(url, apiheader);
        console.log(data);

        settotalPage(data?.data?.total / data?.data?.perPage);
        setData(data?.data?.data);
        if (data?.status === 200) {
            setTimeout(() => {
                // setIsLaoding(true)
            }, 200);
        }
    }
    useEffect(() => {
        fetchProfileData(currentPage);
    }, [currentPage])
    const onPageChange = (event) => {
        const newPage = event.first;
        setCurrentPage(newPage);
        setRows(event.rows);
    };
    // delete products
    const confirmDeleteproducts = async (products) => {
        setDeleteproductsDialog(true);
        setIdDeleteproducts(products.id)
    };
    //   products iamges
    const confirmProducts = async (products) => {
        setProductsDialog(true);
    };
    const deleteproducts = async () => {
        setdeleteLoadind(true)
        await axios.delete(`${process.env.REACT_APP_API_URL}/products/${IdDeleteproducts}`, apiheader).then(({ data }) => {
            console.log(data);
            if (data?.status) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.message, life: 3000 });

                setTimeout(() => {
                    setDeleteproductsDialog(false);
                    fetchProfileData()
                    setdeleteLoadind(false)
                }, 1000);
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setdeleteLoadind(false)
        })

    };

    // handel image in tabel
    const imageBodyTemplate = (rowData) => {
        return <img src={rowData.image} alt={rowData.image} className="shadow-2 border-round" style={{ width: '150px' }} />;
    };
    const colorTemplate = (rowData) => {
        return (
            <div className='flex flex-row gap-3'>
                {rowData.colors?.map((colorItem) => (<span key={colorItem.id} className='colors_tabel' style={{ backgroundColor: colorItem.color }}>  </span>))}
            </div>
        );
    };

    const hideDeleteproductsDialog = () => setDeleteproductsDialog(false);
    const hideProductsDialog = () => setProductsDialog(false);
    const deleteproductsDialogFooter = (
        <div className='btn_gapAr'>
            <Button label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" outlined onClick={hideDeleteproductsDialog} />
            <Button label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} icon="pi pi-check" severity="danger" onClick={deleteproducts} />
        </div>
    );

    // handel btn action in tabel 
    const imagesProductsTemplate = (rowData) => {
        return (
            <div className="btn_gapAr group flex justify-content-center gap-4 ">
                <Button size='small' label={isLang==="en"?'show':'عرض'}  icon="pi pi-external-link" onClick={() => {
                    confirmProducts(rowData)
                    setImages(rowData.images)
                }} />
            </div>
        );
    };
    const itemTemplate = (item) => {
        return <img src={item.image} alt={item.image} style={{ width: '100%',objectFit:'contain',height:'400px', display: 'block' }} />;
    }

    
    return (
        <>

            <>
                <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
                <div className="card">
                    <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>

                    <DataTable
                        ref={dt}
                        value={Data}
                        dataKey="id"
                        globalFilter={globalFilter}
                        header={header}>
                        <Column field="image" header={isLang === "en" ? "Product" : 'المنتج'} body={imageBodyTemplate}></Column>
                        <Column field="name_en" header={isLang === "en" ? "  Name (En)" : ' اسم المنتج  (En)'} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        <Column field="name_ar" header={isLang === "en" ? "  Name (Ar)" : ' اسم المنتج  (Ar) '} style={{ minWidth: '16rem', textAlign: 'center' }}></Column>
                        <Column field="description_en" header={isLang === "en" ? "  Description (En)" : ' وصف المنتج  (En)'} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        <Column field="description_ar" header={isLang === "en" ? "  Description (Ar)" : ' وصف المنتج  (Ar) '} style={{ minWidth: '16rem', textAlign: 'center' }}></Column>
                        <Column body={imagesProductsTemplate} header={isLang === "en" ? "Products" : 'المنتجات'} style={{ minWidth: '5rem' }}></Column>
                        <Column field="category_name" header={isLang === "en" ? "category" : 'تصنيف'} style={{ minWidth: '5rem', textAlign: 'center' }}></Column>
                        <Column field="sub_category_name" header={isLang === "en" ? "sub_category " : 'فئة فرعية'} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
                        <Column field="colors" header={isLang === "en" ? "colors" : 'الالوان'} body={colorTemplate}></Column>
                        <Column field="width" header={isLang === "en" ? " width" : 'عرض'} style={{ minWidth: '5rem', textAlign: 'center' }}></Column>
                        <Column field="height" header={isLang === "en" ? "  height" : 'طول'} style={{ minWidth: '5rem', textAlign: 'center' }}></Column>
                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>


                    </DataTable>

                    <div dir='ltr'>
                        <Paginator first={(currentPage) * rows} rows={rows} totalRecords={totalPage * rows} onPageChange={onPageChange} />
                    </div>
                </div>

                <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={productsDialog} style={{ width: '60%' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Products gallery' : 'معرض منتجات '} modal onHide={hideProductsDialog}>
                    <div className='flex justify-content-center align-items-center'>
                        <Galleria
                            value={images}
                            responsiveOptions={responsiveOptions}
                            numVisible={5}
                            style={{ maxWidth: '70%' }}
                            showThumbnails={false} showIndicators 
                            autoPlay={true}
                            item={itemTemplate}   /> 
                    </div>
                </Dialog>



                <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteproductsDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Confirm Delete' : 'تأكيد الحذف'} modal footer={deleteproductsDialogFooter} onHide={hideDeleteproductsDialog}>
                    <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        <span>
                            {isLang === "en" ? " Are you sure you want to delete   ?" : ' هل أنت متأكد أنك تريد حذف   ؟   '}
                        </span>
                    </div>
                </Dialog>

            </>


        </>
    );
}
export default ProductsData