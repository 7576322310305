import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import img from '../../assets/Img';
import { PostData, apiheader } from '../../utils/fetchData';
import { VendersContext } from '../../context/Store';
import { InputTextarea } from 'primereact/inputtextarea';

const AddOffers = () => {
  let { isLang } = useContext(VendersContext);

    const url = `${process.env.REACT_APP_API_URL}/offers`;
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    let navigate = useNavigate()
    const items = [
        { label: <Link to={'/offers'}>  {isLang === "en" ? "offers  " : 'عروض'} </Link> },
        { label: <Link to={'/offers/add'} className='bg-orange-100 p-2 border-round'>{isLang === "en" ? "Add offers  " : 'اضافة عرض جديد'}   </Link> }
    ];

    const [selectedImage, setSelectedImage] = useState(null);
    const handleImageSelect = (event) => {
        setSelectedImage(event.target.files[0]);
    };
    const home = { icon: 'pi pi-home', url: '/' };
    const formik = useFormik({
        initialValues: {
            title_en: '',
            title_ar: '',
            description_en: '',
            description_ar: '',
            image: null,
        },
        onSubmit: async (values, { resetForm }) => {
            console.log(values);
            try {

                setLoading(true);
                let { data } = await PostData(url, values, apiheader);
                console.log(data);
                if (data?.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.message, life: 3000 });
                    setTimeout(() => {
                        setLoading(false);
                        navigate('/offers')
                        resetForm();
                    }, 1000);
                } else if (data?.status === 400) {
                    toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.message, life: 3000 });
                    resetForm();
                    setLoading(false);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: data?.message, life: 3000 });
                }

            } catch ({ response }) {
                console.error(response.data.message);
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
                setLoading(false);

            }
        }
    });
    useEffect(() => {
        formik.setFieldValue('image', selectedImage);
    }, [selectedImage]);
    return (
        <div>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <BreadCrumb model={items} home={home} />
            <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
                <div className="mt-3 bg-blue-400 flex justify-content-center m-auto " style={{ position: 'relative', marginBottom: '30px', width: 'fit-content' }}>
                    {formik.values.image ? (
                        <img
                            loading="lazy"
                            src={URL.createObjectURL(formik.values.image)}
                            alt={formik.values.image.name}
                            className=' rounded-circle mx-auto '
                            style={{ border: '6px solid #fff', width: '300px', height: '200' }}
                        />
                    ) :
                        <>
                            <img
                                loading="lazy"
                                src={img.DefaultImage}
                                alt={'default-image'}
                                className=' rounded-circle mx-auto '
                                style={{ border: '6px solid #fff', width: '300px', maxHeight: '172' }}
                            />
                        </>
                    }
                    <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                        <input
                            type="file"
                            id="file-input"
                            accept="image/*"
                            onChange={handleImageSelect}
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="file-input" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                            <i className='pi pi-image color-wight'></i>
                        </label>
                    </div>
                </div>
                <div className="grid  mt-5  ">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="title_en" className="font-bold block mb-2">   {isLang === "en" ? "title_en" : 'العنوان بالانجليزي'}      </label>
                        <InputText name='title_en' id="title_en" keyfilter="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
                        <label htmlFor="title_ar" className="font-bold block mb-2">   {isLang === "en" ? "title_ar" : 'العنوان بالعربي '} </label>
                        <InputText name='title_ar' id="title_ar" keyfilter="text" className="w-full  p-inputtext-sm" dir='rtl' onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>
                </div>

                <div className="grid  mt-2   ">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="description_en" className="font-bold block mb-2">   {isLang === "en" ? "description_en" : '  وصف بالانجليزي'}     </label>
                         <InputTextarea rows={5} name='description_en' id="description_en" keyfilter="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
                        <label htmlFor="description_ar" className="font-bold block mb-2">  {isLang === "en" ? "description_ar" : 'وصف بالعربي'}  </label>
                         <InputTextarea rows={5} name='description_ar' id="description_ar" keyfilter="text" className="w-full  p-inputtext-sm" dir='rtl'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>
                </div>

                <div className="btn_gapAr group flex justify-content-center gap-4 ">
                    <Button icon="pi pi-check" loading={loading} label={isLang == "en" ? 'Submit' : 'إرسال  '} type="submit" size='small' className='mt-3' />
                    <Link to={'/offers'}>
                        <Button label={isLang == "en" ? 'Cancel' : 'إلغاء  '} type="reset" outlined size='small' className='mt-3' />
                    </Link>
                </div>
            </form>
        </div>
    )
}

export default AddOffers
