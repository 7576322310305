import React, { createContext, useEffect, useState } from 'react';
import { GetData, apiheader } from '../utils/fetchData';
export const VendersContext = createContext([])

function VenderContext({ children }) {
  const [isOpen, setIsOpen] = useState(true);
  const [Profile, setProfile] = useState(true);
  const [isLang, setIsLang] = useState(localStorage.getItem('langChange'));
  const toggle = () => setIsOpen(!isOpen);
  async function LogOut() {
    const url = `${process.env.REACT_APP_API_URL}/auth/logout`;
    // await PostData(url, {}, apiheader).then((data) => {
    //   console.log(data);
    // }).catch((error) => {
    //   console.log(error);
    // })

    localStorage.removeItem("tockenClick");
  }

  const chnageLang = () => {
    if (!localStorage.getItem('langChange')) {
      setIsLang('en')
      return localStorage.setItem('langChange', 'en')
    } else {
      return localStorage.setItem('langChange', isLang)
    }
  }

  const fetchProfile = async () => {
    const url = `${process.env.REACT_APP_API_URL}/auth/profile`;
    let data = await GetData(url, apiheader);
    setProfile(data?.data);
  }
  useEffect(() => {

    chnageLang()

  }, [isLang])

  useEffect(() => {

    fetchProfile()

  }, [])


  return (
    <>
      <VendersContext.Provider value={{
        isLang, setIsLang, isOpen, setIsOpen, toggle, LogOut, Profile, fetchProfile
      }}>
        {children}
      </VendersContext.Provider>
    </>
  )
}

export default VenderContext