
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from "primereact/inputtextarea";
import { Skeleton } from 'primereact/skeleton';

import React, { useContext, useRef, useState } from 'react';
import { VendersContext } from './../../context/Store';
import { PostData, apiheader } from '../../utils/fetchData';

const OurFooter = ({ isLaoding, data, section, setIsLaoding, toast }) => {
    let { isLang } = useContext(VendersContext);
    const urlUpdateData = `${process.env.REACT_APP_API_URL}/settings/update-data`;

    const [disabled, setDisabled] = useState(true)
    const [loadingBtn, setloadingBtn] = useState(false)

    const description_en = useRef();
    const description_ar = useRef();
    const address_en = useRef();
    const address_ar = useRef();
    const phone = useRef();
    const whats = useRef();
    const email = useRef();
    const facebook = useRef();
    const twitter = useRef();
    const instagram = useRef();
    const youtube = useRef();
    const linked_in = useRef();

    const submit = e => {
        e.preventDefault()
        editFooter({
            section: 'footer',
            'settings[description_en]': description_en.current.value,
            'settings[description_ar]': description_ar.current.value,
            'settings[address_en]': address_en.current.value,
            'settings[address_ar]': address_ar.current.value,
            'settings[phone]': phone.current.value,
            'settings[whats]': whats.current.value,
            'settings[email]': email.current.value,
            'settings[facebook]': facebook.current.value,
            'settings[twitter]': twitter.current.value,
            'settings[instagram]': instagram.current.value,
            'settings[youtube]': youtube.current.value,
            'settings[linked_in]': linked_in.current.value,
        })
    }

    async function editFooter(Footer) {

        try {
            setloadingBtn(true);
            let { data } = await PostData(urlUpdateData, Footer, apiheader);
            if (data?.status === 200) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: data?.message, life: 3000 });
                setTimeout(() => {
                    setloadingBtn(false);
                    setDisabled(true)
                }, 1000);
            } else if (data?.status === 400) {
                toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.message, life: 3000 });
                setloadingBtn(false);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: data?.message, life: 3000 });
            }

        } catch ({ response }) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
            setloadingBtn(false);
        }
    }
    return (
        <div>
            <div className='grid  btn_gapAr  mt-5  w-11  m-auto'>
                <Button label={`${disabled ? `${isLang === "en" ? 'Edit' : '  تعديل'}` : `${isLang === "en" ? 'Show data' : '  عرض البيانات'}`}`} icon={`${disabled ? 'pi pi-file-edit' : 'pi pi-check-circle'}`} onClick={() => setDisabled(!disabled)} />
            </div>

            <form onSubmit={submit}>

                <div className="grid  mt-3  w-11  m-auto">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        {isLaoding ? <label htmlFor="settings[description_en]" className="font-bold block mb-2">
                            {isLang === "en" ? 'description_en' : '  محتوي بالإنجليزية'}

                        </label> : <Skeleton width="5rem" className="mb-3"></Skeleton>}
                        {isLaoding ?
                             <InputTextarea rows={5} disabled={disabled} id="settings[description_en]"
                                name='settings[description_en]'
                                ref={description_en}
                                defaultValue={data?.description_en}
                                keyfilter="text" className="w-full p-inputtext-sm" />
                            : <Skeleton width="100%" height="3rem"></Skeleton>}
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
                        {isLaoding ? <label htmlFor="settings[description_ar]" className="font-bold block mb-2">
                            {isLang === "en" ? 'description_ar' : '     محتوي بالعربية'}
                        </label> : <Skeleton width="5rem" className="mb-3"></Skeleton>}
                        {isLaoding ?  <InputTextarea rows={5} disabled={disabled} id="settings[description_ar]"
                            name='settings[description_ar]'
                            ref={description_ar}
                            defaultValue={data?.description_ar}
                            dir='rtl' keyfilter="email" className="w-full p-inputtext-sm" /> : <Skeleton width="100%" height="3rem"></Skeleton>}

                    </div>
                </div>

                <div className="grid w-11  m-auto">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        {isLaoding ? <label htmlFor="settings[address_en]" className="font-bold block mb-2">
                            {isLang === "en" ? 'address_en' : '  عنوان بالإنجليزية'}

                        </label> : <Skeleton width="5rem" className="mb-3"></Skeleton>}
                        {isLaoding ?
                            <InputTextarea disabled={disabled} id="settings[address_en]"
                                name='settings[address_en]'
                                ref={address_en}
                                defaultValue={data?.address_en}
                                keyfilter="text" className="w-full p-inputtext-sm" /> : <Skeleton width="100%" height="3rem"></Skeleton>}
                    </div>

                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        {isLaoding ? <label htmlFor="settings[address_ar]" className="font-bold block mb-2">
                            {isLang === "en" ? 'address_ar' : '  عنوان بالعربية'}
                        </label> : <Skeleton width="5rem" className="mb-3"></Skeleton>}
                        {isLaoding ?
                            <InputTextarea disabled={disabled} id="settings[address_ar]"
                                name='settings[address_ar]'
                                ref={address_ar}
                                defaultValue={data?.address_ar}
                                dir='rtl'
                                keyfilter="text" className="w-full p-inputtext-sm" /> : <Skeleton width="100%" height="3rem"></Skeleton>}
                    </div>
                </div>

                <div className="grid w-11  m-auto">
                    <div className="lg:col-4 md:col-12 sm:col-12 mt-2 input__Col ">
                        {isLaoding ? <label htmlFor="settings[phone]" className="font-bold block mb-2">
                            {isLang === "en" ? 'phone' : '  رقم الهاتف  '}
                        </label> : <Skeleton width="5rem" className="mb-3"></Skeleton>}
                        {isLaoding ? <InputText disabled={disabled}
                            name='settings[phone]'
                            ref={phone}
                            dir='ltr'
                            defaultValue={data?.phone}
                            id="settings[phone]"
                            keyfilter="text" className="w-full p-inputtext-sm" /> : <Skeleton width="100%" height="3rem"></Skeleton>}
                    </div>
                    <div className="lg:col-4 md:col-12 sm:col-12   mt-2 input__Col">

                        {isLaoding ? <label htmlFor="settings[whats]" className="font-bold block mb-2">
                            {isLang === "en" ? 'whatsapp' : '  رقم واتساب  '}

                        </label> : <Skeleton width="5rem" className="mb-3"></Skeleton>}
                        {isLaoding ? <InputText keyfilter="int" name='settings[whats]'
                            disabled={disabled} id="settings[whats]" dir='ltr'
                            ref={whats}
                            defaultValue={data?.whats}
                            className="w-full p-inputtext-sm" /> : <Skeleton width="100%" height="3rem"></Skeleton>}

                    </div>
                    <div className="lg:col-4 md:col-12 sm:col-12   mt-2 input__Col">

                        {isLaoding ? <label htmlFor="settings[email]" className="font-bold block mb-2">
                            {isLang === "en" ? 'email' : '   بريد إلكتورني    '}

                        </label> : <Skeleton width="5rem" className="mb-3"></Skeleton>}
                        {isLaoding ? <InputText disabled={disabled} name='settings[email]'
                            ref={email}
                            defaultValue={data?.email}
                            keyfilter="int" className="w-full p-inputtext-sm" /> : <Skeleton width="100%" height="3rem"></Skeleton>}
                    </div>
                </div>

                <div className="grid w-11  m-auto">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        {isLaoding ? <label htmlFor="settings[facebook]" className="font-bold block mb-2">
                            {isLang === "en" ? 'facebook' : 'فيس بوك      '}

                        </label> : <Skeleton width="5rem" className="mb-3"></Skeleton>}
                        {isLaoding ?
                            <InputText disabled={disabled} id="settings[facebook]"
                                name='settings[facebook]'
                                ref={facebook}
                                defaultValue={data?.facebook}
                                keyfilter="text" className="w-full p-inputtext-sm" />
                            : <Skeleton width="100%" height="3rem"></Skeleton>}
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
                        {isLaoding ? <label htmlFor="settings[twitter]" className="font-bold block mb-2">
                            {isLang === "en" ? 'twitter' : 'تويتر        '}

                        </label> : <Skeleton width="5rem" className="mb-3"></Skeleton>}
                        {isLaoding ? <InputText disabled={disabled} id="settings[twitter]"
                            name='settings[twitter]'
                            ref={twitter}
                            defaultValue={data?.twitter}
                            keyfilter="email" className="w-full p-inputtext-sm" /> : <Skeleton width="100%" height="3rem"></Skeleton>}

                    </div>
                </div>

                <div className="grid w-11  m-auto">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        {isLaoding ? <label htmlFor="settings[youtube]" className="font-bold block mb-2">
                            {isLang === "en" ? 'youtube' : 'يوتيوب  '}

                        </label> : <Skeleton width="5rem" className="mb-3"></Skeleton>}
                        {isLaoding ?
                            <InputText disabled={disabled} id="settings[youtube]"
                                name='settings[youtube]'
                                ref={youtube}
                                defaultValue={data?.youtube}
                                keyfilter="text" className="w-full p-inputtext-sm" />
                            : <Skeleton width="100%" height="3rem"></Skeleton>}
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
                        {isLaoding ? <label htmlFor="settings[instagram]" className="font-bold block mb-2">
                            {isLang === "en" ? 'instagram' : 'إنستجرام   '}
                        </label> : <Skeleton width="5rem" className="mb-3"></Skeleton>}
                        {isLaoding ? <InputText disabled={disabled} id="settings[instagram]"
                            name='settings[instagram]'
                            ref={instagram}
                            defaultValue={data?.instagram} keyfilter="instagram" className="w-full p-inputtext-sm" /> : <Skeleton width="100%" height="3rem"></Skeleton>}

                    </div>
                </div>

                <div className="grid w-11  m-auto">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        {isLaoding ? <label htmlFor="settings[linked_in]" className="font-bold block mb-2">
                            {isLang === "en" ? 'LinkedIn' : 'لينكد إن   '}

                              </label> : <Skeleton width="5rem" className="mb-3"></Skeleton>}
                        {isLaoding ?
                            <InputText disabled={disabled} id="settings[linked_in]"
                                name='settings[linked_in]'
                                ref={linked_in}
                                defaultValue={data?.linked_in}
                                keyfilter="text" className="w-full p-inputtext-sm" />
                            : <Skeleton width="100%" height="3rem"></Skeleton>}
                    </div>
                </div>

                {
                    !disabled &&
                    <div className="group btn_gapAr flex justify-content-center gap-4 ">
                        <Button icon="pi pi-check" loading={loadingBtn} label={isLang == "en" ? 'Submit' : 'إرسال  '} type="submit" size='small' className='mt-3' />

                    </div>
                }
            </form>

        </div>
    )
}

export default OurFooter
