import loginBg from "./Images/loginBg.png";
import logo from "./Images/logo.png";
import DefaultImage from "./Images/default-image.jpg";
import UploadImage from "./Images/upload image.png";

const img = {
    loginBg,
    logo,
    DefaultImage,
    UploadImage
}

export default img;
