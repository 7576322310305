import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import img from '../../assets/Img';
import { PostData, apiheader } from '../../utils/fetchData';
import { VendersContext } from './../../context/Store';

const AddLocations = () => {
  let { isLang } = useContext(VendersContext);
  const url = `${process.env.REACT_APP_API_URL}/locations`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()
  const items = [
      { label: <Link to={'/locations'}>  {isLang === "en" ? "locations  " : 'مواقع'} </Link> },
      { label: <Link to={'/locations/add'} className='bg-orange-100 p-2 border-round'> {isLang === "en" ? "Add location  " : 'اضافة موقع'} </Link> }
  ];

  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageSelect = (event) => {
      setSelectedImage(event.target.files[0]);
  };
  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
      initialValues: {
          lat: '',
          long: '', 
      },
      onSubmit: async (values, { resetForm }) => {
          console.log(values);
          try {

              setLoading(true);
              let { data } = await PostData(url, values, apiheader);
              console.log(data);
              if (data?.status === 200) {
                  toast.current.show({ severity: 'success', summary: 'Success', detail: data?.message, life: 3000 });
                  setTimeout(() => {
                      setLoading(false);
                      navigate('/locations') 
                      resetForm(); 
                  }, 1000);
              } else if (data?.status === 400) {
                  toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.message, life: 3000 });
                  resetForm();
                  setLoading(false);
              } else {
                  toast.current.show({ severity: 'error', summary: 'Error', detail: data?.message, life: 3000 });
              }

          } catch ({ response }) {
              console.error(response.data.message);
              toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
              setLoading(false);

          }
      }
  });
  useEffect(() => {
      formik.setFieldValue('image', selectedImage);
  }, [selectedImage]);
  return (
      <div>
          <Toast ref={toast} position={isLang==="en"?'top-right':'top-left'} />

          <BreadCrumb model={items} home={home} />
          <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
       
              <div className="grid  mt-5  ">
                  <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                      <label htmlFor="lat" className="font-bold block mb-2">   {isLang === "en" ? "lat" : 'خطوط العرض'}      </label>
                      <InputText name='lat' id="lat" keyfilter="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
                          onBlur={formik.handleBlur} />
                  </div>
                  <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
                      <label htmlFor="long" className="font-bold block mb-2">   {isLang === "en" ? "long" : 'خطوط الطول'} </label>
                      <InputText name='long' id="long" keyfilter="text" className="w-full  p-inputtext-sm" dir='rtl' onChange={formik.handleChange}
                          onBlur={formik.handleBlur} />
                  </div>
              </div>
 

              <div className="group btn_gapAr flex justify-content-center gap-4 ">
                  <Button icon="pi pi-check"  loading={loading}    label={isLang == "en" ? 'Submit' : 'إرسال  '}  type="submit" size='small' className='mt-3' />
                  <Link to={'/locations'}>
                      <Button label={isLang == "en" ? 'Cancel' : 'إلغاء  '} type="reset" outlined size='small' className='mt-3' /> 
                  </Link>
              </div>
          </form>
      </div>
  );
};

export default AddLocations