import { createBrowserRouter,createHashRouter, Navigate, RouterProvider } from 'react-router-dom';
import Component from './constants/Component';
import VenderContext from './context/Store';
import './style/App.scss';
import LocationsData from './Pages/Locations/LocationsData';

function App() {
  function ProtectedRoutes({ children }) {
    let token = localStorage.getItem('tockenClick')
    if (!token) {
      return <Navigate to="/admin/login" replace={true} />
    } else {
      return children;
    }
  }
  const root = createBrowserRouter([
    {
      path: '', element: <Component.Vendor />, children: [
        { index: true, element: <ProtectedRoutes><Component.Dashboard /></ProtectedRoutes> }, 
 
        {
          path: '/slider', children: [
            { index: true, element: <ProtectedRoutes><Component.SliderData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddSlider /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateSlider /></ProtectedRoutes> }
          ]
        },
        {
          path: '/about', children: [
            { index: true, element: <ProtectedRoutes><Component.AboutData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddAbout /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateAbout /></ProtectedRoutes> }
          ]
        },
        {
          path: '/features', children: [
            { index: true, element: <ProtectedRoutes><Component.FeaturesData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddFeatures /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateFeatures /></ProtectedRoutes> }
          ]
        },
        {
          path: '/values', children: [
            { index: true, element: <ProtectedRoutes><Component.OurValuesData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddOurValues /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateOurValues /></ProtectedRoutes> }
          ]
        },
        {
          path: '/qualities', children: [
            { index: true, element: <ProtectedRoutes><Component.QualitiesData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddQualities/></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateQualities /></ProtectedRoutes> }
          ]
        },
        {
          path: '/goals', children: [
            { index: true, element: <ProtectedRoutes><Component.GoalsData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddGoals/></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateGoals /></ProtectedRoutes> }
          ]
        },
        {
          path: '/offers', children: [
            { index: true, element: <ProtectedRoutes><Component.OffersData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddOffers /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateOffers /></ProtectedRoutes> }
          ]
        },
        {
          path: '/categories', children: [
            { index: true, element: <ProtectedRoutes><Component.CategoriesData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddCategories /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateCategories /></ProtectedRoutes> }
          ]
        },
        {
          path: '/products', children: [
            { index: true, element: <ProtectedRoutes><Component.ProductsData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddProducts /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateProducts /></ProtectedRoutes> }
          ]
        },
        {
          path: '/locations', children: [
            { index: true, element: <ProtectedRoutes><Component.LocationsData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddLocations /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateLocations /></ProtectedRoutes> }
          ]
        },
        {
          path: '/services', children: [
            { index: true, element: <ProtectedRoutes><Component.ServicesData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddServices /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateServices /></ProtectedRoutes> }
          ]
        },

        {
          path: '/blogs', children: [
            { index: true, element: <ProtectedRoutes><Component.BlogsData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddBlogs /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateBlogs /></ProtectedRoutes> }
          ]
        },

        {
          path: '/projects', children: [
            { index: true, element: <ProtectedRoutes><Component.ProjectsData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddProjects /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateProjects /></ProtectedRoutes> }
          ]
        },

        {
          path: '/clients', children: [
            { index: true, element: <ProtectedRoutes><Component.ClientsData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddClients /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateClients /></ProtectedRoutes> }
          ]
        },

        { path: '/settings/:section', element: <ProtectedRoutes><Component.Settings /></ProtectedRoutes> },
        { path: '/contact-us', element: <ProtectedRoutes><Component.ContactUs /></ProtectedRoutes> },
        { path: '/jobs', element: <ProtectedRoutes><Component.Jobs /></ProtectedRoutes> },

        { path: '/profile', element: <Component.Profile /> },
        {
          path: '*', element: <Component.Error />
        }
      ],
    },
    {
      path: '/admin/', element: <Component.Auth />, children: [
        { path: 'login', element: <Component.Login /> },
      ]
    },
  ])


  return (
    <div>

      <VenderContext>
        <RouterProvider router={root} />
      </VenderContext>

    </div>
  );
}

export default App;