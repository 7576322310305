import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetData, apiheader } from '../../utils/fetchData';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import InfiniteScroll from 'react-infinite-scroll-component';
import { VendersContext } from '../../context/Store';

const OurValuesData = () => {
    let { isLang } = useContext(VendersContext);

    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [deletevaluesDialog, setDeletevaluesDialog] = useState(false);
    const [IdDeletevalues, setIdDeletevalues] = useState(null);
    const [Data, setData] = useState([]);
    const [deleteLoadind, setdeleteLoadind] = useState(false);
  
    const [currentPage, setCurrentPage] = useState(1); // Initialize current page number
    const [totalPages, setTotalPages] = useState(1); // Initialize total pages to 1
  
    // Add new values
    const leftToolbarTemplate = () => {
      return (
        <div className="flex flex-wrap  btn_gapAr gap-2">
          <Link to="/values/add">
            <Button label={isLang === "en" ? "New values" : 'إضافة قيمنا'} icon="pi pi-plus" size='small' />
          </Link>
        </div>
      );
    };
  
 
  
    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
      return (
        <div className="flex gap-2">
          <Link to={`/values/edit/${rowData.id}`}>
            <Button icon="pi pi-pencil" rounded outlined className="mr-2" />
          </Link>
          <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeletevalues(rowData)} />
        </div>
      );
    };
    const header = (
      <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
        <h4 className="m-0">{isLang === "en" ? "Search by title or description " : '   البحث حسب العنوان أو الوصف '} </h4>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={isLang === "en" ? "Search..." : ' بحـــث... '} />
        </span>
      </div>
    );
  
  
    const getAllContactUsvalues = async (page) => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/values/?page=${page}`;
        const data = await GetData(url, apiheader);
        setTotalPages(data?.data?.lastPage || 1);
        return data?.data?.data;
      } catch (error) {
        console.error('Error while fetching contact data:', error);
        return [];
      }
    };

    const fetchMoreData = () => {
      if (currentPage < totalPages) {
        const nextPage = currentPage + 1; // Calculate the next page
        getAllContactUsvalues(nextPage).then((newData) => {
          setData((prevData) => [...prevData, ...newData]);
          setCurrentPage(nextPage); // Update the currentPage state
        });
      }
    };
  
    useEffect(() => {
      getAllContactUsvalues(currentPage).then((data) => {
        setData(data);
      });
    }, []);
  
    const confirmDeletevalues = async (values) => {
      setDeletevaluesDialog(true);
      setIdDeletevalues(values.id)
    };
    const deletevalues = async () => {
      setdeleteLoadind(true)
      await axios.delete(`${process.env.REACT_APP_API_URL}/values/${IdDeletevalues}`, apiheader).then(({ data }) => {
        console.log(data);
        if (data?.status) {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.message, life: 3000 });
  
          setTimeout(() => {
            setDeletevaluesDialog(false);
            getAllContactUsvalues(currentPage)
            setdeleteLoadind(false)
          }, 1000);
        }
      }).catch((error) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
        setdeleteLoadind(false)
      })
  
    };
    const hideDeletevaluesDialog = () => setDeletevaluesDialog(false);
    const deletevaluesDialogFooter = (
      <div className='btn_gapAr'>
        <Button label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" outlined onClick={hideDeletevaluesDialog} />
        <Button label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} icon="pi pi-check" severity="danger" onClick={deletevalues} />
      </div>
    );
  
    return (
      <div>
        <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
        <div className="card">
          <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
          <InfiniteScroll
            dataLength={Data.length}
            next={fetchMoreData}
            hasMore={currentPage < totalPages}
            loader={<h4>Loading...</h4>}
          >
            <DataTable
              ref={dt}
              value={Data}
              dataKey="id"
              globalFilter={globalFilter}
              header={header}>
              <Column field="title_en" header={isLang === "en" ? "title_en" : 'العنوان بالانجليزي'} style={{ minWidth: '11rem', textAlign: 'center' }}></Column>
              <Column field="title_ar" header={isLang === "en" ? "title_ar" : 'العنوان بالعربي '} style={{ minWidth: '11rem', textAlign: 'center' }}></Column>
              <Column field="description_en" header={isLang === "en" ? "description_en" : '  وصف بالانجليزي'} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
              <Column field="description_ar" header={isLang === "en" ? "description_ar" : 'وصف بالعربي'} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
              <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
            </DataTable>
          </InfiniteScroll>
        </div>
  
        <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deletevaluesDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Confirm Delete' : 'تأكيد الحذف'} modal footer={deletevaluesDialogFooter} onHide={hideDeletevaluesDialog}  >
          <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
            <span>
              {isLang === "en" ? " Are you sure you want to delete   ?" : ' هل أنت متأكد أنك تريد حذف   ؟   '}
            </span>
          </div>
        </Dialog>
      </div>
    )
  }

export default OurValuesData