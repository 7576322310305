// ==============================Sidebar======================================
import { ReactComponent as Contact } from '../../assets/svg/call-calling.svg';
import { ReactComponent as Dashboard } from '../../assets/svg/dashboard.svg';
// ===========================================================================
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right.svg';
import { ReactComponent as PasswordHidden } from '../../assets/svg/password hidden.svg';
import { ReactComponent as PasswordOpen } from '../../assets/svg/password open.svg';
import { ReactComponent as Service } from '../../assets/svg/Service.svg';
import { ReactComponent as Home } from '../../assets/svg/Home Outline.svg';
import { ReactComponent as Aboutus } from '../../assets/svg/aboutus.svg';
import { ReactComponent as Blogs } from '../../assets/svg/Blogs.svg';
import { ReactComponent as Projects } from '../../assets/svg/Projects.svg';
import { ReactComponent as Clients } from '../../assets/Images/svg/clients.svg';
import { ReactComponent as Setting } from '../../assets/svg/setting-2.svg';
import { ReactComponent as Dote } from '../../assets/svg/dote.svg';
import { ReactComponent as ContactUs } from '../../assets/svg/ContactUs.svg';
import { ReactComponent as Jops } from '../../assets/svg/Jops.svg';

const LogoSvg = {
    // ==============================Sidebar======================================
    Dashboard,
    Contact,
    // ===========================================================================
    ArrowRight,
    PasswordHidden,
    PasswordOpen,
    Service,
    Home,
    Aboutus,
    Blogs,
    Projects,
    Clients,
    Setting,
    Dote,
    ContactUs,
    Jops

}

export default LogoSvg;
