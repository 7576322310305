
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetData, apiheader } from '../../utils/fetchData';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { VendersContext } from '../../context/Store';
import { Paginator } from 'primereact/paginator';


const CategoriesData = () => {
    let { isLang } = useContext(VendersContext);

    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [deletecategoriesDialog, setDeletecategoriesDialog] = useState(false);
    const [IdDeletecategories, setIdDeletecategories] = useState(null);
    const [Data, setData] = useState(null);
    const [deleteLoadind, setdeleteLoadind] = useState(false);
    const [totalPage, settotalPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(0); // State to keep track of the current page
    const [rows, setRows] = useState(1);


    // Add new categories
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2">
                <Link to="/categories/add">
                    <Button label={isLang === "en" ? "New categories  " : '    إضافة قســم جديدة    '} size='small' icon="pi pi-plus"  />
                </Link>
            </div>
        );
    }; 

    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2">
                <Link to={`/categories/edit/${rowData.id}`}>
                    <Button icon="pi pi-pencil" rounded outlined className="mr-2" />
                </Link>
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeletecategories(rowData)} />
            </div>
        );
    };
    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">{isLang === "en" ? "Search by category name " : '    إبحث حسب اسم القســم '} </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={isLang === "en" ? "Search..." : ' بحـــث... '} />
            </span>

        </div>
    );

    const fetchProfileData = async (page) => {
        const url = `${process.env.REACT_APP_API_URL}/categories/?page=${page + 1}`;
        let data = await GetData(url, apiheader);
        console.log(data);

        settotalPage(data?.data?.total / data?.data?.perPage);
        setData(data?.data?.data);
        if (data?.status === 200) {
            setTimeout(() => {
                // setIsLaoding(true)
            }, 200);
        }
    }
    useEffect(() => {
        fetchProfileData(currentPage);
    }, [currentPage])
    const onPageChange = (event) => {
        const newPage = event.first;
        setCurrentPage(newPage);
        setRows(event.rows);
    };
    // delete categories
    const confirmDeletecategories = async (categories) => {
        setDeletecategoriesDialog(true);
        setIdDeletecategories(categories.id)
    };
    const deletecategories = async () => {
        setdeleteLoadind(true)
        await axios.delete(`${process.env.REACT_APP_API_URL}/categories/${IdDeletecategories}`, apiheader).then(({ data }) => {
            console.log(data);
            if (data?.status) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.message, life: 3000 });

                setTimeout(() => {
                    setDeletecategoriesDialog(false);
                    fetchProfileData()
                    setdeleteLoadind(false)
                }, 1000);
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setdeleteLoadind(false)
        })

    };
    const hideDeletecategoriesDialog = () => setDeletecategoriesDialog(false);
    const deletecategoriesDialogFooter = (
        <div className='btn_gapAr'>
            <Button label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" outlined onClick={hideDeletecategoriesDialog} />
            <Button label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} icon="pi pi-check" severity="danger" onClick={deletecategories} />
        </div>
    );
    return (
        <div>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">
                <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>

                <DataTable
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    header={header}>
                    <Column field="name_en" header={isLang === "en" ? "Categories name (En)" : ' اسم القســم  (En)'} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="name_ar" header={isLang === "en" ? "Categories name (Ar)" : ' اسم القســم  (Ar) '} style={{ minWidth: '16rem', textAlign: 'center' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>

                <div dir='ltr'>
                    <Paginator first={(currentPage ) * rows} rows={rows} totalRecords={totalPage * rows} onPageChange={onPageChange} />
                </div>
            </div>

            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deletecategoriesDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Confirm Delete' : 'تأكيد الحذف'} modal footer={deletecategoriesDialogFooter} onHide={hideDeletecategoriesDialog}>
                <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>
                        {isLang === "en" ? " Are you sure you want to delete   ?" : ' هل أنت متأكد أنك تريد حذف   ؟   '}
                    </span>
                </div>
            </Dialog>
        </div>
    );
}

export default CategoriesData
