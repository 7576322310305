import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetData, PostData, apiheader } from '../../utils/fetchData';
import { VendersContext } from '../../context/Store';
import { Dropdown } from 'primereact/dropdown';

const UpdateCategories = () => {
  let { isLang } = useContext(VendersContext);
  let { id } = useParams()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/categories/${id}/update`;
  const urlFetchData = `${process.env.REACT_APP_API_URL}/categories/${id}`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const [parentId, setParentId] = useState([]);

  const toast = useRef(null);
  let navigate = useNavigate()
  const items = [
    { label: <Link to={'/categories'}>{isLang === "en" ? "categories  " : 'الأقســام'}  </Link> },
    { label: <Link to={`/categories/edit/${id}`} className='bg-orange-100 p-2 border-round'>   {isLang === "en" ? "Update categories" : 'تعديل القســم  '}</Link> }
  ];
  const fetchcategoriesData = async () => {
    let { data } = await GetData(urlFetchData, apiheader)
    setData(data);
  }
  useEffect(() => {
    fetchcategoriesData()
  }, []);
  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      name_en: Data?.name_en || '',
      name_ar: Data?.name_ar || '',
      parent_id: Data?.parent_id || '',
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let { data } = await PostData(urlUpdateData, values, apiheader);
        console.log(data);
        if (data?.status === 200) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.message, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/categories')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.message, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.message, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);

      }
    }
  });


  useEffect(() => {
    formik.setValues({
      name_en: Data?.name_en || '',
      name_ar: Data?.name_ar || '',
      parent_id: Data?.parent_id || '',
    });
  }, [Data, formik.setValues]);
  const fetchParentIdData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/categories/get/parent-categories`;
    let data = await GetData(url, apiheader);
    setParentId(data?.data);
    console.log(data?.data);
  }
  useEffect(() => {
    fetchParentIdData();
  }, [])
  return (
    <div>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="name_en" className="font-bold block mb-2">   {isLang === "en" ? "Categories name (En)" : ' اسم القســم  (En)'}    </label>
            <InputText
              name='name_en'
              id="name_en"
              keyfilter="text"
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name_en}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="name_ar" className="font-bold block mb-2"> {isLang === "en" ? "Categories name (ِAr)" : ' اسم القســم  (ِAr)'}  </label>
            <InputText value={formik.values.name_ar} name='name_ar' id="name_ar" keyfilter="text" className="w-full  p-inputtext-sm" dir='rtl' onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-2   ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="parent_id" className="font-bold block mb-2"> {isLang === "en" ? "categorie " : '   حدد القسم '}   </label>
            <Dropdown
              options={parentId?.map(item => ({
                name: isLang === "en" ? item?.name_en : item?.name_ar,
                value: item.id,
              }))}
              filter
              id="parent_id"
              name="parent_id"
              optionLabel="name"
              optionValue="value"
              value={formik.values.parent_id} // Add this line 
              onChange={(e) => formik.setFieldValue("parent_id", e.value)}
              onBlur={formik.handleBlur}
              placeholder={isLang === "en" ? 'Select a parent_id' : 'اختر '}
              className="w-full p-inputtext-sm"
            />
          </div>

        </div>

        <div className="btn_gapAr group flex justify-content-center gap-4 ">
          <Button icon="pi pi-check" loading={loading} label={isLang == "en" ? 'Submit' : 'إرسال  '} type="submit" size='small' className='mt-3' />
          <Link to={'/categories'}>
            <Button label={isLang == "en" ? 'Cancel' : 'إلغاء  '} type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form>
    </div>
  );
}

export default UpdateCategories
