import Vendor from '../Layout/Vendor';
import Navber from './../Components/Navber/Navber';
import Error from './../Components/NotFound/Error';
import Sildebar from './../Components/Sidebar/Sildebar';
import Auth from './../Layout/Auth';
import ContactUs from './../Pages/ContactUs/ContactUs';
import Dashboard from './../Pages/Dashbord/Dashbord';
import Profile from './../Pages/Profile/Profile';
import Settings from './../Pages/Settings/Settings';
import AddSlider from './../Pages/Slider/AddSlider';
import SliderData from './../Pages/Slider/SliderData';
import UpdateSlider from './../Pages/Slider/UpdateSlider';
import Login from './../Pages/auth/Login/Login';
import AddClients from './../Pages/Clients/AddClients';
import ClientsData from './../Pages/Clients/ClientsData';
import UpdateClients from './../Pages/Clients/UpdateClients';
import AboutData from './../Pages/About/AboutData';
import UpdateAbout from './../Pages/About/UpdateAbout';
import AddAbout from './../Pages/About/AddAbout';
import ServicesData from './../Pages/OurServices/ServicesData';
import AddServices from './../Pages/OurServices/AddServices';
import UpdateServices from './../Pages/OurServices/UpdateServices';
import FeaturesData from './../Pages/Features/FeaturesData';
import AddFeatures from './../Pages/Features/AddFeatures';
import UpdateFeatures from './../Pages/Features/UpdateFeatures';
import OffersData from './../Pages/Offers/OffersData';
import AddOffers from './../Pages/Offers/AddOffers';
import UpdateOffers from './../Pages/Offers/UpdateOffers';
import OurValuesData from './../Pages/OurValues/OurValuesData';
import AddOurValues from './../Pages/OurValues/AddOurValues';
import UpdateOurValues from './../Pages/OurValues/UpdateOurValues';
import QualitiesData from './../Pages/Qualities/QualitiesData';
import AddQualities from './../Pages/Qualities/AddQualities';
import UpdateQualities from './../Pages/Qualities/UpdateQualities';
import GoalsData from './../Pages/Goals/GoalsData';
import AddGoals from './../Pages/Goals/AddGoals';
import UpdateGoals from './../Pages/Goals/UpdateGoals';
import CategoriesData from './../Pages/categories/CategoriesData';
import AddCategories from './../Pages/categories/AddCategories';
import UpdateCategories from './../Pages/categories/UpdateCategories';
import AddProducts from './../Pages/products/AddProducts';
import ProductsData from './../Pages/products/ProductsData';
import UpdateProducts from './../Pages/products/UpdateProducts';
import AddLocations from './../Pages/Locations/AddLocations';
import LocationsData from './../Pages/Locations/LocationsData';
import UpdateLocations from './../Pages/Locations/UpdateLocations';

const Component = {
    // Layout 
    Vendor,
    Auth,
    Dashboard, 
    Login,
    Navber,
    Sildebar,
    Error,
    Profile,
    // Pages Right click  
    SliderData,
    AddSlider,
    UpdateSlider, 
    Settings,
    ContactUs, 
    AddClients,
    ClientsData,
    UpdateClients,
    AboutData,
    UpdateAbout,
    AddAbout,
    ServicesData,
    AddServices,
    UpdateServices,
    FeaturesData,
    AddFeatures,
    UpdateFeatures,
    OffersData,
    AddOffers,
    UpdateOffers,
    OurValuesData,
    AddOurValues,
    UpdateOurValues,
    QualitiesData,
    AddQualities,
    UpdateQualities,
    GoalsData,
    AddGoals,
    UpdateGoals,
    CategoriesData,
    AddCategories,
    UpdateCategories,
    AddProducts,
    ProductsData,
    UpdateProducts, 
    AddLocations,
    LocationsData,
    UpdateLocations, 
}

export default Component


